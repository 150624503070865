import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from '../styles/Home.module.scss';
import stylesN from '../styles/News.module.scss';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { useEffect, useState } from 'react';
import Swiperthumbs from './swiperthumbs';
export default function NewsBannerSwiper({ featuredArticlesJson, isHomePage = false }) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [activeSlide, setActiveSlide] = useState(0);
    const [bannerWidth, setBannerWidth] = useState(1133);
    useEffect(() => {
        // add listener for resize
        window.addEventListener('resize', () => {
            adjustBannerWidth();
        });
        // orientation change
        window.addEventListener('orientationchange', function () {
            adjustBannerWidth();
        });
        adjustBannerWidth();
    }, [activeSlide, thumbsSwiper]);
    const adjustBannerWidth = () => {
        var _a, _b;
        const mainBanner = document.getElementById('newsBannerSwiper');
        let activeSlide = 0;
        if (mainBanner != null) {
            activeSlide = (_b = (_a = mainBanner.getElementsByClassName('slider-content')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.clientWidth;
        }
        setBannerWidth(activeSlide + 40);
    };
    const handleRedirect = (url) => {
        window.location.href = url;
    };
    const getSlidePerView = () => {
        if ((featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.length) === undefined) {
            return 1.1;
        }
        else if ((featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.length) > 1) {
            return 1.1;
        }
        else {
            return 1;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("style", { children: `
            @media (max-width: 540px) {
                .headerSwiper {
                    height: 54rem !important;
                }
            }
          
            .newsFeaturedArticlesSwiper {
                height: 170px;
                box-sizing: border-box;
                padding: 10px 0;
                position: absolute;
                width: 100%;
                bottom: 0;
                margin-left: auto;
                margin-right: auto;
                max-width: var(--max-width-md);
                width: 100%;

                @media (max-width: 856px) {
                    display: none;
                }
            }

            .headerSwiper {
                height: 100%;
                width: 100%;
            }
      ` }), _jsxs("div", { className: stylesN.newsSwiperMainContainer, children: [_jsxs("div", { className: `${stylesN.mainBannerSlider} ${isHomePage ? stylesN.mainBannerSliderHome : ''}`, children: [_jsx(Swiper, { breakpoints: {
                                    300: {
                                        slidesPerView: 1
                                    },
                                    768: {
                                        slidesPerView: getSlidePerView()
                                    }
                                }, slidesPerView: getSlidePerView(), loop: true, centeredSlides: true, thumbs: { swiper: thumbsSwiper }, modules: [FreeMode, Navigation, Thumbs], spaceBetween: 10, scrollbar: { draggable: true }, onSlideChange: (sw) => { setActiveSlide(sw.realIndex); }, id: 'newsBannerSwiper', className: 'headerSwiper', children: featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.map((res, index) => {
                                    var _a, _b;
                                    return (_jsxs(SwiperSlide, { children: [_jsx("div", { className: stylesN.bannerImageContainer, children: _jsxs("picture", { children: [_jsx("source", { media: "(max-width: 540px)", srcSet: (_a = res.BannerImagesMobile) === null || _a === void 0 ? void 0 : _a[0] }), _jsx("source", { media: "(min-width: 541px)", srcSet: (_b = res.BannerImages) === null || _b === void 0 ? void 0 : _b[0] }), _jsx("img", { src: res.BannerImages[0], alt: res.Title })] }) }), _jsxs("div", { className: `${stylesN.slideContentOverlay} ${stylesN['slideContentOverlay--gradinet']}`, children: [_jsx("div", { className: stylesN.slideContentContainer, children: _jsx("div", { className: `${stylesN.slideContentWrapper} ${stylesN['slideContentWrapper--mainSlider']} ${index === activeSlide ? 'slider-content' : ''}`, children: _jsxs("div", { className: stylesN.slideContent, children: [_jsx("span", { className: stylesN.slideSubTitle, children: res.Type }), _jsx("h2", { className: stylesN.slideTitle, children: res.Title }), _jsx("p", { className: stylesN.slideSubTitle, children: res.Description }), _jsx("span", { children: _jsx("div", { onClick: () => handleRedirect(res.Url), className: `${styles.button} ${styles['button--6']} ${isHomePage ? stylesN.buttonHome : ''}`, children: "Read" }) })] }) }) }), _jsx("div", { className: stylesN.gradient })] })] }, res.Title + 'mainSlider'));
                                }) }), _jsx(Swiper, { onSwiper: setThumbsSwiper, loop: true, slidesPerView: 4, freeMode: true, modules: [FreeMode, Navigation, Thumbs], className: `newsFeaturedArticlesSwiper ${stylesN.hideInMobile}`, style: { maxWidth: bannerWidth + 'px' }, children: featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.map((res, index) => {
                                    return (_jsx(SwiperSlide, { className: `${stylesN.hideInMobile}`, children: _jsx("div", { className: `${stylesN.slideContentOverlay}`, children: _jsx("div", { className: stylesN.slideContentContainer, children: _jsx("div", { className: `${stylesN.slideContentWrapper} ${stylesN['slideContentWrapper--thubnailSlider']}`, children: _jsx("div", { className: stylesN.thumbGalleryContainer, children: _jsxs("div", { className: stylesN['thumbGalleryContainer--wrapper'], children: [_jsx(Swiperthumbs, { width: activeSlide === index ? 100 : 0, isBanner: true }), _jsx("span", { className: activeSlide === index ? stylesN.isActive : '', children: res.Type }), _jsx("h3", { className: activeSlide === index ? stylesN.isActive : '', children: res.Title })] }) }) }) }) }) }, res.Title + 'slideContent'));
                                }) })] }), _jsx("div", { className: stylesN.mobilethumbGalleryContainer, children: featuredArticlesJson === null || featuredArticlesJson === void 0 ? void 0 : featuredArticlesJson.map((res, index) => {
                            return _jsx(Swiperthumbs, { width: activeSlide === index ? 100 : 0 }, 'Swiperthumbs' + index);
                        }) })] })] }));
}
